var generic = generic || {};
var site = site || {};

(function ($) {
  Drupal.behaviors.localeInterstitial = {
    attach: function (context, settings) {
      var prefCountry, prefLanguage;
      var interstitialContainer = $('#localeInterstitial');
      var countryContainer = $('#localeInterstitial .country-list');
      var countryLink = $('#localeInterstitial a.country-link');
      var selectedCountry = $('.current-country');
      var languageList = $('#localeInterstitial .language-list');
      var languageLink = $('#localeInterstitial .language-list li');
      var localeButton = $('#localeInterstitial #chooseLocaleButton');
      var resizeEvt;
      var locale = generic.cookie('LOCALE'); // get locale settings from browser if it's set

      if ([undefined, null, '', 'en_EU', 'en_ND'].indexOf(locale) > -1) {
        displayLocaleInterstitial();
        $('.block-template-country-picker-eu-v1').hide(); // hide country picker when interstitial is being shown, otherwise can be seen in bg as an empty rect
      }

      $(document).bind('displayLocaleInterstitial', function () {
        // action to let other modules show the enterstitial (e.g. mobile country picker)
        displayLocaleInterstitial();
      });

      countryLink.bind('click', function (e) {
        countryContainer.find('input[type=radio]').attr('checked', false);
        countryContainer.find('.picker-radio').removeClass('checked');
        if ($(this).attr('href') === '#change-country') {
          $(this).find('input[type=radio]').attr('checked', true);
          $(this).find('.picker-radio').addClass('checked');
          prefCountry = $(this).data('country').toUpperCase();
          countryLink.removeClass('selected');
          $(this).addClass('selected');
          selectedCountry.html($(this).data('countryname'));
          languageLink.removeClass('selected');
          var countryLangs = $(this).data('languages').split(',');

          if (!prefLanguage || countryLangs.indexOf(prefLanguage) === -1) { // set preferred language to country's default if not defined or not available
            prefLanguage = countryLangs[0];
          }
          languageList.html(site.getLangsFromCountryLink($(this)).join(''));
          languageLink.removeClass('selected current-lang');
          interstitialContainer.find('.language-list li').each(function () {
            $(this).append('<br><label><input type="radio" class="language-select" name="selectlanguage"></label>');
            if ($(this).data('language') === prefLanguage) {
              $(this).trigger('click');
            }
          });
          $('input[type=radio]').picker();
          var langSelect = $('#localeInterstitial .language-list .picker-radio');

          langSelect.on('click', function () {
            $(this).closest('li').click();
          });
          if (prefCountry && prefLanguage) {
            localeButton.attr('disabled', false); // enable the choose locale button once both locale variables are set
          } else {
            localeButton.attr('disabled', true); // disable the choose locale button unless both locale variables are set
          }
          $('#localeInterstitial .initial-hide').show();
          site.centerModal(interstitialContainer);
        } else {
          $(location).attr('href', $(this).attr('href'));
        }
      });
      var localeSelect = $('#localeInterstitial input.country-select');

      localeSelect.bind('click', function (e) {
        $(this).closest('a').click();
      });

      languageLink.live('click', function (e) {
        languageList.find('input[type=radio]').attr('checked', false);
        languageList.find('.picker-radio').removeClass('checked');
        $(this).find('input[type=radio]').attr('checked', true);
        $(this).find('.picker-radio').addClass('checked');
        prefLanguage = $(this).data('language');
        languageList.find('li').removeClass('selected current-lang');
        $(this).addClass('selected');
        if (prefCountry && prefLanguage) {
          localeButton.attr('disabled', false); // enable the choose locale button once both locale variables are set
        } else {
          localeButton.attr('disabled', true); // disable the choose locale button unless both locale variables are set
        }
      });

      localeButton.bind('click', function () {
        site.changeLocale(prefCountry, prefLanguage);
      });

      $(window).bind('resize', function () {
        clearTimeout(resizeEvt);
        resizeEvt = setTimeout(function () {
          site.centerModal(interstitialContainer);
        }, 250);
      });

      function displayLocaleInterstitial() {
        generic.jsonrpc.fetch({
          method: 'locale.detect',
          params: [],
          onSuccess: function (jsonRpcResponse) {
            setOverlayLocale(jsonRpcResponse.getValue());
          },
          onFailure: function (jsonRpcResponse) {
            // jsonRpcResponse.getError();
            setOverlayLocale();
          }
        });
        formatLocaleOptions();
        site.centerModal(interstitialContainer);
      }

      function formatLocaleOptions() {
        $('#localeInterstitial li a').each(function () {
          var flag = $(this).find('img').remove()[0];
          var text = $(this).html();

          $(this).empty();
          $(this).append(text + '<br>');
          $(this).append(flag, '<br>');
          $(this).append('<label><input type="radio" class="country-select" name="selectcountry"><span class="checkmark"></span></label>');
        });
        $('#localeInterstitial .country-list, #localeInterstitial .language-list').cleanWhitespace();
      }

      function setOverlayLocale(overlayLocale) {
        if (!overlayLocale) {
          localeButton.attr('disabled', true); // disable the choose locale button unless a locale is provided
        } else {
          locale = overlayLocale; // default country and language selections
          var localeParts = locale.split('_'); // don't need local variable

          prefLanguage = localeParts[0].toLowerCase();
          prefCountry = localeParts[1].toLowerCase();
        }

        if (prefCountry && prefLanguage) {
          localeButton.attr('disabled', false); // enable the choose locale button once both locale variables are set
        } else {
          localeButton.attr('disabled', true); // disable the choose locale button unless both locale variables are set
        }

        site.setupLanguageMenu(interstitialContainer, prefCountry, prefLanguage);

        if (prefCountry !== '') {
          $('#localeInterstitial .country-list a.country-link').each(function (e) {
            if ($(this).data('country') === prefCountry) {
              $(this).trigger('click');
            }
          });
        }

        if (prefLanguage !== '') {
          $('#localeInterstitial .language-list li').each(function (e) {
            if ($(this).data('language') === prefLanguage) {
              $(this).trigger('click');
            }
          });
        }

        // finally, append the interstitial to the end of the page
        $('body').append($('#localeOverlay').css({ display: 'block' }));
        site.centerModal(interstitialContainer);
        $('#localeOverlay').find("input[type='radio']").removeClass('picker-element');

        $("#localeInterstitial a.country-link input[name='selectcountry']:radio").on('change', function () {
          $(this).parent().trigger('click');
        });
      }
    }
  };
})(jQuery);
